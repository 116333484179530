<template>
  <b-card>
    <validation-observer
      ref="changePasswordForm"
      #default="{invalid}"
    >
      <!-- form -->
      <b-form @submit.prevent="saveChanges">
        <b-row>
          <!-- old password -->
          <b-col
            v-if="!hideOldPassword"
            md="6"
          >
            <b-form-group
              label="Old Password"
              label-for="account-old-password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-old-password"
                  v-model="passwordValueOld"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  placeholder="Old Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="New Password"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t(`input_fields.new_password`)"
                vid="password"
                :rules="{ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, required }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="account-new-password"
                    ref="password"
                    v-model="user.password"
                    :type="passwordFieldTypeNew"
                    name="password"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t(`input_fields.new_password`)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Retype New Password"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t(`input_fields.confirm_password`)"
                vid="password-confirm"
                :rules="{ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, required, confirmed: 'password' }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="user.password_confirm"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t(`input_fields.confirm_password`)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <b-col
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <span>{{ $t('password_warning') }}</span>
              </div>
            </b-alert>
          </b-col>

          <b-col
            cols="12"
            class="mt-75"
          >
            <!-- server alert -->
            <b-alert
              v-height-fade.appear
              variant="danger"
              :show="serverError !== null"
            >
              <div
                class="alert-body"
              >
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <span v-html="serverError" />
              </div>
            </b-alert>
          </b-col>

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="invalid || loading"
              class="mt-1 mr-1"
            >
              <b-spinner
                v-show="loading"
                small
              />
              <span class="m-1">{{ $t('buttons.save_changes') }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              @click.prevent="resetForm"
            >
              {{ $t('buttons.reset') }}
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAlert, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  heightFade,
} from '@core/directives/animations'
import {
  required,
  regex,
  confirmed,
} from '@validations'
import { mapActions, mapGetters } from 'vuex'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      passwordValueOld: '',
      user: {},
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      hideOldPassword: true,
      required,
      regex,
      confirmed,
      serverError: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('user', ['updateUser']),
    resetForm() {
      this.user.password = ''
      this.user.password_confirm = ''
      this.$refs.changePasswordForm.reset()
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    saveChanges() {
      this.$refs.changePasswordForm.validate().then(async success => {
        if (success) {
          this.serverError = null
          this.$swal({
            title: 'Are you sure?',
            text: 'Updating your password will require you to sign-in again with your new password.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(async result => {
            this.loading = true
            try {
              if (result.value) {
                const userPayload = {
                  id: this.activeUser.id,
                  password: this.user.password,
                }
                await this.$store.dispatch('user/updateUser', userPayload, { root: true })
                // this.$toast({
                //   component: ToastificationContent,
                //   position: 'top-right',
                //   props: {
                //     title: 'Success!',
                //     icon: 'BellIcon',
                //     variant: 'success',
                //     text: 'Your password has been updated.',
                //   },
                // })
                // this.$router.push('/login').catch(() => {})
                this.$swal({
                  icon: 'success',
                  title: 'Updated!',
                  text: 'Your password was successfully updated.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(ok => {
                  if (ok) {
                    this.$router.push('/login').catch(() => {})
                  }
                })
              }
            } catch (error) {
              console.debug(`Settings - Password Save Error: ${error.message}`)
              if (error.response) {
                if (error.response.status < 500) {
                  this.serverError = error.response.data.message
                } else {
                  this.serverError = this.$t('errors.generic')
                }
              }
            } finally {
              this.loading = false
            }
          })
        }
      })
    },
  },
}
</script>
