<template>
  <b-card>
    <b-row>
      <b-col>
        <b-card-text>DenTyme has partnered with <a href="https://stripe.com">Stripe</a> to handle billing. Click on the button below to access your billing portal.</b-card-text>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @click="createBillingSession"
        >
          <b-spinner
            v-if="loading"
            small
          />
          <span class="m-1">Manage my billing on Stripe</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BCardText, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  heightFade,
} from '@core/directives/animations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardText,
    BButton,
    BRow,
    BCol,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
  methods: {
    ...mapActions('billing', ['createStripePortalSession']),
    async createBillingSession() {
      try {
        this.loading = true
        const payload = {
          customer: this.activeUser.office.billing_customer.stripe_customer_id,
        }
        const response = await this.createStripePortalSession(payload)
        const { url } = response.data
        window.location.href = url
      } catch (e) {
        console.debug(`Could not access Stripe billing portal. ${e.message}`)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error!',
            icon: 'XIcon',
            variant: 'danger',
            text: 'There was an error when accessing Stripe. Please try again.',
          },
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
