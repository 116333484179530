<template>
  <b-card>

    <!-- media -->
    <b-media
      v-if="!hideAvatar"
      no-body
    >
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.avatar"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <validation-observer
      ref="generalSettingsForm"
      #default="{invalid}"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="saveChanges"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="First Name"
              label-for="account-first-name"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t(`input_fields.first_name`)"
                vid="first-name"
                :rules="{ regex: /^[\w'\-,.][^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/, required }"
              >
                <b-form-input
                  v-model="optionsLocal.first_name"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t(`input_fields.first_name`)"
                  name="first-name"
                  data-vv-validate-on="blur"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Last Name"
              label-for="account-last-name"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t(`input_fields.last_name`)"
                vid="last-name"
                :rules="{ regex: /^[\w'\-,.][^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/, required }"
              >
                <b-form-input
                  v-model="optionsLocal.last_name"
                  name="last-name"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t(`input_fields.last_name`)"
                  data-vv-validate-on="blur"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="account-e-mail"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t(`input_fields.email`)"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  v-model="optionsLocal.email"
                  name="email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!hideCompany"
            sm="6"
          >
            <b-form-group
              label="Company"
              label-for="account-company"
            >
              <b-form-input
                v-model="optionsLocal.company"
                name="company"
                placeholder="Company name"
              />
            </b-form-group>
          </b-col>

          <!-- alert -->
          <b-col
            v-if="false"
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
              <h4 class="alert-heading">
                Your email is not confirmed. Please check your inbox.
              </h4>
              <div class="alert-body">
                <b-link class="alert-link">
                  Resend confirmation
                </b-link>
              </div>
            </b-alert>
          </b-col>
          <!--/ alert -->

          <!-- server alert -->
          <b-col
            cols="12"
            class="mt-75"
          >
            <b-alert
              v-height-fade.appear
              variant="danger"
              :show="serverError !== null"
            >
              <div
                class="alert-body"
              >
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <span v-html="serverError" />
              </div>
            </b-alert>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              :disabled="invalid || loading"
              class="mt-2 mr-1"
            >
              <b-spinner
                v-show="loading"
                small
              />
              <span class="m-1">{{ $t('buttons.save_changes') }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              {{ $t('buttons.reset') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  email,
  regex,
} from '@validations'
import {
  heightFade,
} from '@core/directives/animations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      hideAvatar: true,
      hideUsername: true,
      hideCompany: true,
      // validation rules
      required,
      email,
      regex,
      serverError: null,
      loading: false,
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
      this.$refs.generalSettingsForm.reset()
    },
    saveChanges() {
      this.$refs.generalSettingsForm.validate().then(async success => {
        if (success) {
          this.serverError = null
          this.loading = true
          try {
            const userPayload = {
              id: this.optionsLocal.id,
              first_name: this.optionsLocal.first_name,
              last_name: this.optionsLocal.last_name,
              email: this.optionsLocal.email,
            }
            await this.$store.dispatch('user/updateUser', userPayload, { root: true })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success!',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Your settings have been updated.',
              },
            })
            // this.$swal({
            //   // title: 'Settings successfully updated!',
            //   text: 'Settings successfully updated!',
            //   icon: 'success',
            //   customClass: {
            //     confirmButton: 'btn btn-primary',
            //   },
            //   buttonsStyling: false,
            // })
          } catch (error) {
            console.debug(`General Settings Save Error: ${error.message}`)
            if (error.response) {
              if (error.response.status < 500) {
                this.serverError = error.response.data.message
              } else {
                this.serverError = this.$t('errors.generic')
              }
            }
          } finally {
            this.loading = false
          }
        }
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
